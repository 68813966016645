import React from 'react';
import Layout from 'components/Layout';
import { FAQSlice } from 'components/Slices';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import { de, en } from '../intl';
import { faqImages } from '../images';
import { addImagesToData } from '../helpers/helpers';

const faq = {
  de: {
    ...de.pages.faq,
    items: addImagesToData(de.pages.faq.items, faqImages),
  },
  en: {
    ...en.pages.faq,
    items: addImagesToData(en.pages.faq.items, faqImages),
  },
};

export default () => {
  return (
    <Layout tabTitlePrefix={'FAQ'} headerInverted>
      <IntlContextConsumer>
        {({ language: currentLocale }) => {
          return <FAQSlice data={faq[currentLocale]} />;
        }}
      </IntlContextConsumer>
    </Layout>
  );
};
